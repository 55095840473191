import React from "react";

import Layout from "../components/common/Layout";
import SEO from "../components/common/SEO";
import ContactForm from "../components/preorder/ContactForm";
import MainRow from "../components/preorder/MainRow";

import "./preorder.css";

const IndexPage = () => {
  return (
    <main>
      <Layout>
        <SEO title="Przedsprzedaż - Hazay Bikes" />
        <MainRow />
        <ContactForm />
      </Layout>
    </main>
  );
};

export default IndexPage;
