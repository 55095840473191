import React, { useState } from "react";

import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "gatsby";

import "./ContactForm.css";

const ContactForm = (props) => {
  const [email, setEmail] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "contact-form.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1300, layout: FULL_WIDTH)
        }
      }
    }
  `);
  return (
    <div
      id="preorder-contact"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "75rem",
        padding: `3rem 1.3125rem 7.625rem`,
        flex: 1,
      }}
    >
      <div className="contact-form-container">
        <div>
          <h2>Dopasujemy rower do Twoich potrzeb</h2>
          <p>Zostaw nam maila, a my zajmiemy się resztą.</p>

          <div style={{ display: "flex", flexWrap: "wrap", zIndex: "5" }}>
            <form
              action="https://app.getresponse.com/add_subscriber.html"
              accept-charset="utf-8"
              method="post"
              style={{ zIndex: 5, marginBottom: "15px" }}
            >
              <input
                className="preorder-signup-input"
                placeholder="Twój adres email"
                name="email"
                type="text"
                required
                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                onChange={(event) => setEmail(event.target.value)}
              ></input>
              <input type="hidden" name="campaign_token" value="runPH" />
              <input
                type="hidden"
                name="thankyou_url"
                value="https://hazaybikes.com/subscription-confirmation/"
              />
              <input type="hidden" name="start_day" value="0" />
              <input
                type="submit"
                className="preorder-row-signup-button"
                value="Wyślij"
                onClick={() => {
                  const valid = /[^@\s]+@[^@\s]+\.[^@\s]+/.test(email);
                  email && valid && setShowConfirmation(true);
                }}
              />
            </form>
            <small>
              * Podając adres e-mail akceptuję{" "}
              <Link style={{ color: "black" }} to="/regulamin">
                Polityke Prywatności
              </Link>
              .
            </small>
          </div>

          <h2>Chcesz przetestować Hazaya?</h2>
          <p>Jesteś w Poznaniu?</p>
          <p>Umówmy się na jazdę testową jednym z prototypów.</p>

          <h3 style={{ marginTop: "4rem" }}>Napisz</h3>
          <p>info@hazaybikes.com</p>

          <h3 style={{ marginTop: "4rem" }}>Zadzwoń</h3>
          <div className="contact-tel-container">
            <p className="contact-form-preorder-page-table-header">
              Marcin Grausch
            </p>
            <p className="contact-form-preorder-page-table-row">
              +48 509 668 294
            </p>

            <p className="contact-form-preorder-page-table-header">
              Kuba Garstkiewicz
            </p>
            <p className="contact-form-preorder-page-table-row">
              +48 608 463 845
            </p>
          </div>
        </div>
        <GatsbyImage image={getImage(data.file)} alt={data.file.name} />
      </div>
    </div>
  );
};

export default ContactForm;
